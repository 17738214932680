#body_container {
  width: 80%;
  margin: auto;
  color: #373737;
  background-color: #f4f4f4;
}

a {
  color: #373737;
  text-decoration: underline;
}

a:visited {
  color: #373737;
  text-decoration: underline;
}

.header {
  text-align: center;
  color: #373737;
  font-family: 'Zilla Slab', serif;
}

.title {
  margin: 1em auto 0.05em;
}

.subtitle {
  margin: 0 auto 0.15em;
}

.date_header {
  margin: 0 auto 0.1em;
}

.category_header {
  margin: 0 auto 0.15em;
}

.tags_header {
  margin: 0;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}
